@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
  }
  
  /* Tailwind custom utility classes for popup animations */
  .popup-animation {
    animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s;
  }